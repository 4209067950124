body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

.margins {
  margin-top: 8em !important;
  padding-bottom: 0em !important;
  position: relative;
  min-height: 100vh;
}


@media only screen and (max-width: 767px) {
  .ui.container.margins {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ui.container.page {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .noPaddingLeft {
    padding-left: 0 !important;
  }
  .intro {
    font-weight: bold;
    padding-left: 0px !important;
  }
  .mobile_buttons {
    min-width: 200px !important;
    margin-bottom: 8px !important;
  }
  .input {
    font-size: 16px !important;
  }
  @-moz-document url-prefix() {
    .masthead {
      min-height: 700px !important;
      padding-bottom: 50px !important;
    }
  }  
  .menu.transition.visible {
    left: 0 !important;
  }
}

.menu.transition.visible {
  right: 0;
  left: auto;
}

.ui.inverted.top.fixed.menu {
  background-color: rgb(52, 58, 64) !important;
}

.bordernone {
  border: none !important;
  box-shadow: none !important;
}

.help-text-width {
  width: 540px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
  padding-bottom: 5em;
}

.modal-text-color {
  color: rgb(52, 58, 64);
}

.modal-button-color {
  background-color: rgb(0, 123, 255) !important;
  color: #fff;
}

.navbar-background-color {
  background-color: rgb(52, 58, 64) !important;
}

.navbar-color {
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-background-color {
  background-color: rgb(248, 249, 250) !important;
}

.fill-height {
  height: 100%;
}

.ui.container {
  width: 1350px !important;
}

.page-width {
  width: 1500px !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}
.vertical-align-top {
  vertical-align: top;
}
/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-color: rgb(52, 58, 64);
}

@media (min-width: 767px) {
.masthead {
  display: flex;
  align-items: center;
  background-color: rgb(52, 58, 64);
  height: 94vh;
}
}
@media (max-width: 992px) {
  .masthead {
    padding-top: 170px !important;
  }
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.full-width {
  width: 100% !important;
}

.footer-link {
  color: rgba(255, 255, 255, 0.9) !important;
}

.right-footer-column {
  text-align: right !important;
}

.left-footer-column {
  text-align: left;
}
.ui.grid {
  margin-left: 0;
}
.card-header {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0 !important;
  color: rgb(73, 80, 87) !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  border-bottom-width: 1px !important;
  border-bottom-color: rgba(0, 0, 0, 0.125) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.card-span-right {
  flex: 1;
  float: right;
  white-space: nowrap;
}
.card-text {
  font-size: 16px;
  display: list-item;
  color: rgb(73, 80, 87);
  font-weight: 400;
  line-height: 24px;
  list-style-type: disc;
  overflow-wrap: break-word;
}
.drop-down {
  width: 300px !important;
}
/*end home page styles*/
.ui.table tr td {
  border-top: 0px !important;
}

.myLabel {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  height: 17px;
  line-height: 17px;
  text-align: left;
  text-indent: 0px;
  margin: 0 0 .3em !important;
}

.ui.label.myLabel.background-color {
  background-color: #fff;
  height: 100%;
}

.ui.secondary.vertical.menu {
  width: 100%;
}

.lineHeight {
  line-height: 21px !important;
}

.footer {
  background-color: rgb(52, 58, 64) !important;
  bottom: 0;
  width: 100vw;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}
.margin-bottom-15px {
  margin-bottom: 15px;
}

.ui.segment {
  border-radius: 0;
}

.page {
  padding-bottom: 2em;
}

.subList {
  padding-left: 11px;
}

.firstList {
  padding-left: 7px;
}

.intro {
  font-weight: bold;
  padding-left: 13px;
}

.terms_bold {
  font-weight: bold;
}

.terms_underlined {
  text-decoration: underline;
}
.card_icon {
  float: left;
}
.card_header {
  color: rgb(73, 80, 87);
  font-size: 16px;
  font-weight: bold;
}
.card_description {
  color: rgb(73, 80, 87);
  font-weight: 400;
  line-height: 24px;
}
.side_note {
  font-size: 10px;
  font-style: italic;
}

.floatRight {
  float: right;
}
.right_padding {
  padding-right: 5px;
}
.save_button {
  padding-top: 2em;
}
.ui.card > .content > .header:not(.ui) {
  line-height: 2.285714em;
}
.padding_top {
  padding-top: 2em;
}
.h1.ui.header {
  font-size: 3rem;
}
.ui.inverted.segment {
  color: rgba(255, 255, 255, 0.9) !important;
}
.ui.basic.button,
.ui.basic.buttons .button {
  background: rgba(255, 255, 255, 0.5) !important;
}

.ui.form .fields {
  margin: 0;
}

.help-text {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  overflow-wrap: break-word;
}

.ui.button.no_padding_image_buttons {
  padding: 0 !important;
}

/* Navbar Styling */

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.ui.header.navBar-loggedOut {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 10px;
}

.navbar.navbar-expand-lg.navbar-dark.fixed-top {
  background: rgba(0, 0, 0, 0.9);
}
.navbar.colorChange.navbar-expand-lg.navbar-dark.fixed-top {
  background: rgba(0, 0, 0, 0.9);
}

@media (min-width: 992px) {
  .navbar.navbar-expand-lg.navbar-dark.fixed-top {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.25)
    );
  }
  .ui.header.navBar-loggedOut {
    color: #000;
  }
}

@media (max-width: 992px) {
  #responsive-navbar-nav.navbar-collapse.collapse.show {
    padding-left: 15px !important;
  }
}

@media (max-width: 389px) {
  .navbarPadding {
    padding-left: 70px !important;
  }
}

@media (min-width: 992px) {
  .navbar.colorChange.navbar-expand-lg.navbar-dark.fixed-top {
    background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    color: white;
  }
}

.navbar-collapse {
  justify-content: flex-end;
}
.navbar-nav {
  padding-right: 2%;
}

.nav-link {
  font-size: medium;
  color: rgba(255, 255, 255, 0.9) !important;
}

.navbarPadding {
  padding-left: 15px;
  padding-right: 15px;
}

.ui.image.navLogo {
  padding-left: 15px;
}

/* End NavBar Styling */

.RTLG_button {
  background-color: #006c9c !important;
  color: #fff !important;
}

.RTP_button {
  background-color: #6b757c !important;
  color: #fff !important;
}

.AE_button {
  background-color: #007043 !important;
}

.linebreaks {
  white-space: pre-wrap;
}

.scrolling {
  overflow: scroll;
}

.modalfix {
  top: auto !important;
  left: auto !important;
  height: auto !important;
}

.modalHeightFixed {
  top: auto !important;
  left: auto !important;
  height: 300 px !important;
}

.textAlignCenter {
  text-align: center;
}

.fontStyleItalic {
  font-style: italic;
}

.input.disabled {
  background-color: rgb(200, 200, 200, 0.3);
}

::placeholder {
  color: rgb(0, 0, 0);
  opacity: 0.8;
}

.myInput {
  border-color: rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.2em 0.6em !important;
  width: 100px !important;
}

.page-Selected {
  border-color: #006c9c !important;
  outline-color: #006c9c !important;
  color: #006c9c !important;
}

.red-checked-strike-thru {
  opacity: 1;
  color: red !important;
  text-decoration: line-through;
}

.margin-left {
  margin-left: 3.5px !important;
}

.required {
  font-style: italic;
  font-weight: bold !important;
}

.MarginTop6px {
  margin-top: 6px !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background-color: rgba(206, 206, 206) !important;
}

.ui.header > .icon {
  font-size: 0.5em;
}

.RemoveTopBottomPadding {
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.marginTop37px {
  margin-top: 37px;
}

.setGridHeight {
  max-height: 300px;
  height: 300px;
}

#userCount.ui.selection.dropdown {
  min-width: 250px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.paddingTopBottom95 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.labelHeight {
  height: 38px;
  max-height: 38px;
}

.paddingLeftRight {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding8 {
  margin: 1px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.width0 {
  max-width: 0;
  padding: 0 !important;
  margin: 0;
}

.width35 {
  width: 35px !important;
  max-width: 35px !important;
}

.width78 {
  min-width: 148px !important;
  max-width: 148px !important;
  width: 148px !important;
}

.width100 {
  min-width: 100px !important;
  max-width: 100px !important;
  width: 100px !important;
}
.width150 {
  min-width: 250px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.width170 {
  min-width: 170px !important;
  max-width: 170px !important;
  width: 170px !important;
}

.maxWidth730 {
  max-width: 730px !important;
}

.width815 {
  max-width: 115px !important;
  width: 115px !important;
}

.alignItemsCenter {
  align-items: center !important;
}

.verticalText {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  border-radius: 0 0.28571429rem 0 0;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.checked.disabled.checkbox .myLabel.checkbox_disabled::after {
  color: red !important;
}

.ui.dropdown.navbarPadding {
  color: rgba(255, 255, 255, 0.55);
}

.ui.card.card_width {
  width: 350px;
}

.ui.container.search-input {
  display: flex;
  flex-flow: row nowrap;
  width: 100% !important;
  padding: 0px;
  vertical-align: bottom;
}

.searchButton {
  margin-top: 22px !important;
  height: 36px;
}

.shortInput {
  width: 70% !important;
}

.ui.icon.right.attached.button.caretButton {
  margin-top: 1px;
}

.ui.icon.left.attached.button.leftButton {
  margin-top: 1px;
}

.printContainer {
  width: 1063px !important;
  border: rgba(34, 36, 38, 0.15) solid 1px;
  padding: 25px;
}
.negative {
  color: red;
}

.logoContainer {
  min-height: 150px;
  overflow-x: hidden;
  border: rgba(34, 36, 38, 0.15) solid 1px;
  margin: 5px;
  padding: 0px !important;
  text-align: center;
  min-width: 330px !important;
  width: 330px !important;
  max-width: 330px !important;
}
.positive {
  color: green;
}

/* recent styling */
.recentSticky {
  position: sticky;
  position: -webkit-sticky;
  top: 95px;
  z-index: 1;
}

@media (min-width: 768px) {
  .cellBottomMobile {
    display: none !important;
  }
}
@media (min-width: 1180px) {
  .boxSizeSame {
    padding: 25px 0 25px !important;
  }
}

.cellInnerBorder td {
  border-right: 1px solid #212529 !important;
}
.cellInnerBorder th {
  border-right: 1px solid #212529 !important;
}

.cellInnerBorder td:nth-child(3) {
  border-right: none !important;
}
.cellInnerBorder th:nth-child(3) {
  border-right: none !important;
}

.recentBorders1 {
  border-top: 4px solid black !important;
  border-right: 4px solid black !important;
  border-left: 4px solid black !important;
}

.recentBordersMid {
  border-right: 4px solid black !important;
  border-left: 4px solid black !important;
}

.recentBorders2 {
  border-bottom: 4px solid black !important;
  border-right: 4px solid black !important;
  border-left: 4px solid black !important;
}

.recentBordersSides {
  border-left: 4px solid black !important;
}

.recentsBorder {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.ui.grid.box_height {
  min-height: 145px;
}
.box_height {
  min-height: 225px;
}
.recentBoxSameSize {
  min-height: 71px;
}
.recentBoxHeight1 {
  min-height: 50px;
}
.recentBoxHeight {
  min-height: 40px;
}
.ui.card.recent_mobile {
  color: black;
}

.column.no_space {
  padding: 5px !important;
}

.two.column.row.zero_space {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
}

.ui.grid.no_margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.intro1 {
  font-weight: bold;
  font-size: larger;
  padding-left: 8px;
}

.largerPaddedLeft {
  font-size: larger;
  padding-left: 8px;
}

.column.table_padding_none {
  padding: 0;
}

.ui.table.no_padding {
  padding: 0;
}

.removeNegativeMargin {
  margin-left: 0 !important;
}

.removeTopMargin {
  margin-top: 0 !important;
}

.removeBottomMargin {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.removeTopPadding {
  padding-top: 0 !important;
}

/* end of recents styling */

.center.aligned.middle.padding_down {
  padding-bottom: 8px;
}

.ui.form .field :disabled {
  opacity: unset !important;
  color: #212529;
  pointer-events: none;
}

.ui.tiny.scrolling.modal.transition.visible.active.modalfix
  div.ui.card.bordernone {
  width: 100%;
}

.ui.tiny.scrolling.modal.transition.visible.active.modalfix {
  margin-top: 100px;
}

.ui.tiny.modal.transition.visible.active.modalfix div.ui.card.bordernone {
  width: 100%;
}

.ui.tiny.modal.transition.visible.active.modalfix {
  margin-top: 60px;
}

.width125px {
  width: 125px !important;
}

.tableLink {
  padding: 0 !important;
  color: #4183c4 !important;
}

.twoHundoWidth {
  min-width: 200px;
}

@media (max-width: 992px) {
  .eleven.wide.column.jobCategoryContainer {
    min-width: 100vw;
  }
}

@media (max-width: 767px) {
  .desktopMenu {
    max-height: 0px !important;
    margin: 0 !important;
    display: none !important;
  }
  .mobileHeight {
    max-height: 7em;
  }
}

@media (min-width: 768px) {
  .mobileDropdownMenu {
    display: none !important;
  }
}
.widerDropdownMenu {
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  line-height: 1;
}

@media (min-width: 660px) {
  .half-width {
    width: 450px !important;
  }
}

@media (max-width: 660px) {
  .row.paddingLeftRight.alignItemsCenter.fourToTwo {
    max-width: 70%;
  }
}
@media (max-width: 500px) {
  .row.paddingLeftRight.alignItemsCenter.fourToTwo {
    max-width: 100vw;
  }
}
.dropdown-custom.visible .menu.transition.visible {
  display: none;
}

@media (min-width: 820px) {
  .dropdown-custom .menu.transition {
    display: block !important;
    visibility: visible !important;
  }

  .style {
    height: 600px;
  }

  .style2 {
    height: 300px;
  }

  .myListbox520 div[role="listbox"] {
    height: 520px;
    min-height: 520px;
  }

  .myListbox254 div[role="listbox"] {
    height: 213px;
    min-height: 213px;
  }
}
@media (max-width: 820px) {
  .eleven.wide.column.full-width {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .widerModal {
    width: 56% !important;
    max-width: 600px !important;
  }
  .widestModal {
    width: 57% !important;
    max-width: 800px !important;
  }
}

.tallModal {
  height: 500px !important;
}

@media only screen and (max-width: 767px) {
  .ui.fluid.pointing.menu {
    max-width: 90% !important;
    min-height: 0%;
  }
  .disabled.item.width815 {
    visibility: collapse !important;
    padding: 0;
    line-height: 0;
  }
  .item.width815 {
    visibility: collapse !important;
    padding: 0 !important;
    line-height: 0 !important;
  }
  .active.disabled.item.width815 {
    visibility: visible !important;
    text-align: center;
    padding: 1.3em 1.14285714em !important;
  }
}

.ui.stackable.grid.paddingContainer {
  padding-left: 3%;
}

.greenRow {
  background-color: #edfee2 !important;
}

/* makes the table cells transparent- bootstrap override */
.table {
  --bs-table-bg: transparent;
}

.widerDropdownMenu {
  min-width: 18em !important;
  border: 2px solid #2185d0 !important;
}
.curvedCorners {
  border-radius: 0.28571429rem;
}
.mobileDropdownMenu {
  max-width: 18em !important;
}
.noBordersDropdown {
  border: none !important;
}
.searchHighlight {
  border: 2px solid #2185d0 !important;
  border-radius: 0.28571429rem;
}

.noBackground {
  background-color: white !important;
}

.modalLoadingContainer {
  min-width: 300px !important;
  min-height: 300px !important;
}

.textAlignRight {
  text-align: right !important;
}
.textAlignRightNotMobile {
  text-align: right !important;
}
.textAlignLeft {
  text-align: left !important;
}
.textAlignLeftButRightMobile {
  text-align: left !important;
}

.textAlignRightBoth {
  text-align: right !important;
}
@media only screen and (max-width: 767px) {
  tablePage {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .table.dnxTable
    tbody
    td.middle.aligned.textAlignLeft.stackIcons.noPaddingTopMobile {
    padding-top: 0px !important;
  }
  table.dnxTable {
    border: none !important;
  }
  tbody {
    border-style: none !important;
    vertical-align: middle !important;
  }
  table.dnxTable th,
  table.dnxTable td {
    padding: 1em 0.5em;
    position: relative;
    min-height: 2em;
  }
  table.dnxTable tr,
  table.dnxTable td,
  table.dnxTable tfoot {
    display: inline-block !important;
    width: 90vw !important;
  }
  /* table.denxTable tbody tr {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  } */
  table.dnxTable tfoot tr {
    border: none !important;
  }
  table.dnxTable td {
    border-bottom: none !important;
    border-left: none !important;
  }
  table.dnxTable tbody tr {
    padding: 0.25em 0;
    border: 1px solid rgba(34, 36, 38, 0.2);
  }
  table.dnxTable tbody tr:not(:last-child) {
    margin-bottom: 0.25em;
  }
  /** important1: hide theader **/
  table.dnxTable thead {
    display: none !important;
  }
  table.dnxTable tbody td.hideMe {
    display: none !important;
  }
  table.dnxTable tbody td {
    padding: 0.3em 0.5em 0.3em 7.5em !important;
  }
  table.dnxTable tbody td.textAlignRight {
    padding: 1em 0.5em 0.3em 1em !important;
  }
  table.dnxTable tbody td.textAlignLeft {
    padding: 1em 0.5em 0.3em 1em !important;
  }
  table.dnxTable tbody td.noPaddingLeft {
    padding-left: 0.4em !important;
  }
  table.dnxTable tbody td.tableInTable {
    padding: 0.78571429em 0.78571429em !important;
  }
  .dnxTable td:not(:last-child) {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  /** important2: use data-label attribute as a table header **/
  table.dnxTable tbody td:before {
    content: attr(data-label);
    left: 0.5em;
    position: absolute;
    top: 0.15em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 6em;
    overflow: inherit;
  }
  .textAlignRight {
    text-align: left !important;
  }
  .textAlignLeft {
    text-align: left !important;
  }
  .textAlignRightNotMobile {
    text-align: unset !important;
  }
  .textAlignLeftButRightMobile {
    text-align: right !important;
  }
  .textAlignRightBoth {
    text-align: right !important;
  }
  table.dnxTable tbody td.noTextAlignMobile {
    text-align: unset !important;
    padding: 1em 0.5em 0.3em 1em !important;
  }
  table.dnxTable thead.needAHeader {
    display: initial !important;
  }
  .addedHeader {
    background-color: transparent !important;
  }
  .ui.input.myInput {
    visibility: collapse !important;
    width: 0 !important;
    padding: 0 !important;
  }
  .ui.label.myLabel.background-color {
    visibility: collapse !important;
    width: 0 !important;
    padding: 0 !important;
  }
  table.recentsMobile thead {
    display: none !important;
  }
  .HomePage nav.navbar.navbar-expand-lg.navbar-dark.fixed-top {
    position: static !important;
  }
  .desktopInvisible {
    visibility: visible !important;
  }
  .mobileInvisible {
    visibility: hidden !important;
    max-width: 0;
    max-height: 0 !important;
    margin: 0 !important;
  }
  .desktopInvisible1 {
    visibility: visible !important;
  }
  .onlyDesktop {
    display: none !important;
  }
  .onlyMobile {
    display: block !important;
  }
  .pdfView {
    height: 800px !important;
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .pdfView {
    height: 500px !important;
    width: 100% !important;
  }
  .onlyDesktop {
    display: none !important;
  }
  .onlyMobile {
    display: block !important;
  }
  .pdfView {
    height: 800px !important;
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .pdfView {
    height: 500px !important;
    width: 100% !important;
  }
}
.stackIcons {
  padding-right: 0 !important;
}

.desktopInvisible {
  visibility: hidden;
  max-width: 0 !important;
  margin: 0 !important;
  height: 0 !important;
}

.desktopInvisible1 {
  visibility: hidden;
  max-width: 0 !important;
  margin: 0 !important;
}
.introPadding {
  padding-left: 20px !important;
}

.email_wrap {
  word-break: break-all;
}

.desc_overflow {
  overflow-wrap: normal;
}

.divider.default.text {
  vertical-align: middle;
}
.tealishButtons {
  background-color: #004b64 !important;
  color: #fff !important;
}

.lessPaddingSysAdminTables {
  padding: 0.18571429em 0.38571429em !important;
}

.sortIconButtons {
  border: none !important;
  background: transparent !important;
  padding: 0.18571429em 0.18571429em 0.18571429em !important;
}

.headerPrint {
  visibility: collapse;
  height: 20px;
}
@media print {
  .printPoof {
    visibility:hidden;
    max-width: 0 !important;
    padding: 0 !important;
  }
  .headerPrint {
    visibility: visible;
    height: 70px;
  }
  .page {
    padding-bottom: 0em;
  }
  .printMargins {
    padding: 24px 24px 0;
  }
}

.onlyMobile {
  display: none;
}

.onlyDesktop {
  display: inherit;
}

.setDisplayNotesHeight {
  max-height: 250px;
  height: 250px;
}

.whitespacePreWrap {
  white-space: pre-wrap;
}